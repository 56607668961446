import { graphql } from 'gatsby';
import React from 'react';
import { StructuredTextDocument } from 'react-datocms/dist/esm';
import { keyframes } from '@emotion/react';
import Container from '../../../primitives/grid/container';
import StyledButton from '../../../primitives/styled-button';
import PlayButton from '../hero/play-button.svg';
import VideoPopover from '../../../primitives/video-popover';
import { breakpoints } from '../../../primitives/tokens';
import Typography from '../../../primitives/typography';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Background from '../../../primitives/backgrounds';
import Asset from '../../../primitives/asset';
import { colors } from '../../../primitives/colors';
import AspectRatio from '../../../primitives/aspect-ratio';

export type FeatureCircleFlowchartProps = Queries.FeatureCircleFlowchartPropsFragment;

const circleKeyframes = keyframes({
    '0%': {
        transform: 'rotate(0deg)',
    },
    '100%': {
        transform: 'rotate(360deg)',
    },
});

const itemKeyframes = keyframes({
    '0%': {
        transform: 'rotate(360deg)',
    },
    '100%': {
        transform: 'rotate(0deg)',
    },
});

const FeatureCircleFlowchart = ({
    richTitle,
    body,
    image,
    video,
    items,
}: FeatureCircleFlowchartProps): JSX.Element => {
    const keyframeTiming = 110;


    return (
        <div css={{
            marginTop: '160px',
            marginBottom: '160px',
            [breakpoints.mb]: {
                marginTop: '78px',
                marginBottom: '64px',
            },
        }}
        >
            <Background breakpoints={{
                dt: {
                    backgroundColor: 'primaryOrange',
                    gradient: 'orange',
                    clip: 'top',
                },
            }}
            >
                <Container
                    css={{
                        marginTop: '110px',
                        marginBottom: '160px',
                        paddingBottom: '80px',
                        [breakpoints.mb]: {
                            marginTop: '40px',
                            marginBottom: '64px',
                            paddingBottom: '40px',
                        },
                    }}
                >
                    <Typography
                        fontSize={{
                            dt: 4053,
                            tb: 3234,
                            mb: 2835,
                        }}
                        override
                        css={{
                            textAlign: 'center',
                            margin: 'auto',
                            maxWidth: '930px',
                            marginBottom: '60px',
                            [breakpoints.mb]: {
                                maxWidth: '100%',
                                marginBottom: '40px',
                            },
                        }}
                        fontWeight="medium"
                        color="shadesWhite"
                    >
                        <CustomStructuredTextRenderer data={richTitle as unknown as StructuredTextDocument} />
                    </Typography>
                    <Row>
                        <Col breakpoints={{
                            dt: { span: 10, start: 1 },
                            tb: { span: 12, start: 0 },
                            mb: { span: 4, start: 0 },
                        }}
                        >
                            <Row>
                                {/*<Col breakpoints={{
                                    dt: { span: 6 },
                                }}
                                >
                                    <div css={{
                                        margin: 'auto',
                                        width: '100%',
                                    }}
                                    >
                                        <Typography
                                            fontSize={{
                                                dt: 2429,
                                                tb: 1822,
                                                mb: 1627,
                                            }}
                                            color="shadesWhite"
                                            fontWeight="light"
                                            css={{
                                                maxWidth: '600px',
                                                marginBottom: '84px',
                                                marginTop: '84px',
                                                [breakpoints.mb]: {
                                                    textAlign: 'center',
                                                    marginTop: '58px',
                                                    marginBottom: '58px',
                                                },
                                                p: {
                                                    marginBottom: '32px',
                                                    [breakpoints.tb]: {
                                                        marginBottom: '18px',
                                                    },
                                                    [breakpoints.mb]: {
                                                        marginBottom: '12px',
                                                    },
                                                },
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: body,
                                            }}
                                        />
                                        <VideoPopover video={video[0].video} link={video[0].link}>
                                            <StyledButton
                                                variant="darkSecondary"
                                                css={{
                                                    minWidth: '148px',
                                                }}
                                            >
                                                <PlayButton />
                                                <span>{video[0].title}</span>
                                            </StyledButton>
                                        </VideoPopover>
                                    </div>
                                </Col>*/}
                                <Col breakpoints={{
                                    dt: { span: 3 },
                                    tb: { span: 1 },
                                    mb: { span: 4 }
                                }} />
                                <Col breakpoints={{
                                    dt: { span: 6 },
                                    tb: { span: 10 },
                                    mb: { span: 4}
                                }}
                                >
                                    <div css={{
                                        position: 'relative',
                                        padding: '40px',
                                    }}
                                    >
                                        <AspectRatio breakpoints={{
                                            dt: { width: 1, height: 1 },
                                        }}
                                        >
                                            <div css={{
                                                width: '50%',
                                                height: '50%',
                                                left: '25%',
                                                top: '25%',
                                                padding: '10%',
                                                backgroundColor: colors.shadesWhite,
                                                borderRadius: '500px',
                                                position: 'absolute',
                                            }}>
                                                <svg css={{
                                                    width: '100%',
                                                    height: '100%',
                                                }} width="64" height="74" viewBox="0 0 64 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M37.1594 50.2942L22.1448 36.2223L27.4609 30.5757L37.0157 39.5207L56.0892 20.6805V13.779C56.0892 10.9109 53.7724 8.59843 50.8988 8.59843H13.057C10.1834 8.59843 7.86658 10.9109 7.86658 13.779V41.7077C7.86658 55.0087 18.6605 65.7822 31.9869 65.7822C45.2952 65.7822 56.1072 55.0087 56.1072 41.7077V31.5795L37.1594 50.2942Z" fill="#FF4F00"/>
                                                </svg>
                                            </div>
                                            <div css={{
                                                width: '100%',
                                                height: '100%',
                                                border: `1px solid ${colors.shadesWhite}`,
                                                borderRadius: '500px',
                                                position: 'relative',
                                                animation: `${circleKeyframes} ${keyframeTiming}s linear infinite`,
                                            }}>
                                                {items?.map((item, idx) => {
                                                    const centreX = 50; // centre x of circle
                                                    const centreY = 50; // centre y of circle
                                                    const r = 50; // radius
                                                    const angleDegreesPerSegment = 360 / items.length;
                                                    const angleDeg = angleDegreesPerSegment * idx; // degree in angle from top
                                                    const radians = angleDeg * (Math.PI / 180);
                                                    const pointY = centreY - (Math.cos(radians) * r); // specific point y on the circle for the angle
                                                    const pointX = centreX + (Math.sin(radians) * r); // specific point x on the circle for the angle

                                                    return (
                                                        <div css={{
                                                            width: '80px',
                                                            position: 'absolute',
                                                            left: `calc(${pointX}% - 40px)`,
                                                            top: `calc(${pointY}% - 40px)`,
                                                        }}
                                                        >
                                                            <AspectRatio breakpoints={{
                                                                dt: { width: 1, height: 1 },
                                                            }}
                                                            >
                                                                <div css={{
                                                                    animation: `${itemKeyframes} ${keyframeTiming}s linear infinite`,
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    backgroundColor: colors.shadesWhite,
                                                                    color: colors.primaryOrange,
                                                                    borderRadius: '500px',
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    gap: '4px',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    padding: '4px',
                                                                }}
                                                                >
                                                                    <Asset
                                                                        css={{
                                                                            width: '19px',
                                                                            height: '19px',
                                                                        }}
                                                                        asset={item.icon}
                                                                    />
                                                                    <Typography
                                                                        fontSize={{
                                                                            dt: 1120,
                                                                        }}
                                                                        css={{
                                                                            lineHeight: '1!important',
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        {item.title}
                                                                    </Typography>
                                                                </div>
                                                            </AspectRatio>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </AspectRatio>

                                    </div>
                                    <Typography
                                        fontSize={{
                                            dt: 2429,
                                            tb: 1822,
                                            mb: 1627,
                                        }}
                                        color="shadesWhite"
                                        fontWeight="light"
                                        css={{
                                            maxWidth: '600px',
                                            marginBottom: '84px',
                                            [breakpoints.mb]: {
                                                textAlign: 'center',
                                                marginBottom: '58px',
                                            },
                                        }}
                                    />
                                    <VideoPopover video={video[0].video} link={video[0].link}>
                                        <StyledButton
                                            variant="darkSecondary"
                                            css={{
                                                minWidth: '148px',
                                            }}
                                        >
                                            <PlayButton />
                                            <span>{video[0].title}</span>
                                        </StyledButton>
                                    </VideoPopover>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Background>
        </div>
    );
}

export default FeatureCircleFlowchart;

export const query = graphql`
    fragment FeatureCircleFlowchartProps on DatoCmsFeatureCircleFlowchart {
        __typename
        richTitle {
            blocks
            links
            value
        }
        body
        image {
            ...DatoCmsInternalAsset
        }
        video {
            ...VideoPopoverButtonProps
        }
        items {
            icon {
                ...DatoCmsInternalAsset
            }
            title
        }
    }
`;
